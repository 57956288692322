<template>
  <nav aria-label="Sidebar" class="sticky top-4 divide-y divide-gray-300">
    <div class="space-y-1 pb-8">
      <router-link
        to="/organization/overview"
        class="group flex items-center rounded-md px-3 py-2 text-sm font-medium text-gray-600 hover:bg-gray-50"
        aria-current="false"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="-ml-1 mr-3 h-6 w-6 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M7 21a4 4 0 01-4-4V5a2 2 0 012-2h4a2 2 0 012 2v12a4 4 0 01-4 4zm0 0h12a2 2 0 002-2v-4a2 2 0 00-2-2h-2.343M11 7.343l1.657-1.657a2 2 0 012.828 0l2.829 2.829a2 2 0 010 2.828l-8.486 8.485M7 17h.01"
          />
        </svg>
        <span class="truncate text-lg"> Overview </span>
      </router-link>
      <!-- <router-link
        to="/organization/activity"
        class="text-gray-600 hover:bg-gray-50 group flex items-center px-3 py-2 text-sm font-medium rounded-md"
        aria-current="false"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="text-gray-400 group-hover:text-gray-500 flex-shrink-0 -ml-1 mr-3 h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M13 10V3L4 14h7v7l9-11h-7z"
          />
        </svg>
        <span class="truncate"> Activity </span>
      </router-link> -->
      <router-link
        to="/organization/team"
        class="group flex items-center rounded-md px-3 py-2 text-sm font-medium text-gray-600 hover:bg-gray-50"
        aria-current="false"
      >
        <UserGroupIcon
          class="-ml-1 mr-3 h-6 w-6 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
        />
        <span class="truncate text-lg"> Team </span>
      </router-link>

      <router-link
        to="/organization/roles"
        class="group flex items-center rounded-md px-3 py-2 text-sm font-medium text-gray-600 hover:bg-gray-50"
        aria-current="false"
      >
        <CollectionIcon
          class="-ml-1 mr-3 h-6 w-6 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
        />
        <span class="truncate text-lg"> Roles </span>
      </router-link>
      <!-- <router-link
        to="/organization/customers"
        class="text-gray-600 hover:bg-gray-50 group flex items-center px-3 py-2 text-sm font-medium rounded-md"
        aria-current="false"
      >
        <UsersIcon
          class="text-gray-400 group-hover:text-gray-500 flex-shrink-0 -ml-1 mr-3 h-6 w-6"
        />
        <span class="truncate text-lg"> Customers </span>
      </router-link>
      <router-link
        to="/organization/invoices"
        class="text-gray-600 hover:bg-gray-50 group flex items-center px-3 py-2 text-sm font-medium rounded-md"
        aria-current="false"
      >
        <DocumentTextIcon
          class="text-gray-400 group-hover:text-gray-500 flex-shrink-0 -ml-1 mr-3 h-6 w-6"
        />
        <span class="truncate text-lg"> Invoices </span>
      </router-link> -->
      <router-link
        to="/organization/billing"
        class="group flex items-center rounded-md px-3 py-2 text-sm font-medium text-gray-600 hover:bg-gray-50"
        aria-current="false"
      >
        <CreditCardIcon
          class="-ml-1 mr-3 h-6 w-6 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
        />
        <span class="truncate text-lg"> Plans & Billing </span>
      </router-link>
      <router-link
        to="/organization/connections"
        class="group flex items-center rounded-md px-3 py-2 text-sm font-medium text-gray-600 hover:bg-gray-50"
        aria-current="false"
      >
        <GlobeIcon
          class="-ml-1 mr-3 h-6 w-6 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
        />
        <span class="truncate text-lg"> Connections </span>
      </router-link>
      <router-link
        to="/organization/settings"
        class="group flex items-center rounded-md px-3 py-2 text-sm font-medium text-gray-600 hover:bg-gray-50"
        aria-current="false"
      >
        <CogIcon
          class="-ml-1 mr-3 h-6 w-6 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
        />
        <span class="truncate text-lg"> Settings </span>
      </router-link>
    </div>
    <!-- <div class="mt-6 pt-6">
      <div class="px-2 space-y-1">
        <a
          v-for="item in secondaryNavigation"
          :key="item.name"
          :href="item.href"
          class="text-gray-600 hover:bg-gray-50 group flex items-center px-3 py-2 text-sm font-medium rounded-md"
        >
          <component
            :is="item.icon"
            class="text-gray-400 group-hover:text-gray-500 flex-shrink-0 -ml-1 mr-3 h-6 w-6"
            aria-hidden="true"
          />
          <span class="truncate text-lg"> {{ item.name }} </span>
        </a>
      </div>
    </div> -->
  </nav>
</template>

<script>
import { useStore } from "vuex";

import {
  CogIcon,
  GlobeIcon,
  // UsersIcon,
  UserGroupIcon,
  CollectionIcon,
  CreditCardIcon,
  // DocumentTextIcon,
} from "@heroicons/vue/outline";

const navigation = [];

// const secondaryNavigation = [
//   { name: "Help", href: "/help", icon: QuestionMarkCircleIcon },
//   { name: "Privacy", href: "/privacy", icon: ShieldCheckIcon },
// ];

export default {
  components: {
    CogIcon,
    GlobeIcon,
    // UsersIcon,
    UserGroupIcon,
    CollectionIcon,
    CreditCardIcon,
    // DocumentTextIcon,
  },
  setup() {
    const store = useStore();
    return {
      store,
      navigation,
    };
  },
};
</script>

<style scoped>
.text-lg {
  font-size: 0.95rem;
}
</style>
